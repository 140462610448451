import { render, staticRenderFns } from "./newsItem.vue?vue&type=template&id=5260c354&"
import script from "./newsItem.vue?vue&type=script&lang=js&"
export * from "./newsItem.vue?vue&type=script&lang=js&"
import style0 from "./newsItem.vue?vue&type=style&index=0&lang=scss&module=true&"
import style1 from "./newsItem.vue?vue&type=style&index=1&lang=scss&"
import style2 from "./newsItem.vue?vue&type=style&index=2&lang=css&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports