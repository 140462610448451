<script>
import { formatNewItemDate } from '@utils/format-date'

import Layout from '@layouts/main'

import { setComputed } from '@state/helpers'

// https://github.com/surmon-china/vue-awesome-swiper
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

// https://www.npmjs.com/package/vue-youtube-embed
// import Vue from "vue";
// import VueYouTubeEmbed from "vue-youtube-embed";
// Vue.use(VueYouTubeEmbed);

// https://github.com/surmon-china/vue-video-player
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";

import NewsSideMenu from '@components/NewsSideMenu'

export default {
  components: { Layout, swiper, swiperSlide, videoPlayer, NewsSideMenu },
  props: {
    category: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      locationHref: location.href,
      breadcrumbs: [
        {
          title: '新訊',
          alias: 'news',
        },
        {
          title: this.item.category_title,
          alias: 'news category',
          params: {
            categoryAlias: this.item.category_alias
          }
        },
        {
          title: this.item.title,
        }
      ],
      playerOptions: {
        // videojs options
        sources: [
          {
            type: "video/mp4",
            src: '',
          }
        ],
        poster: '',
      },
      swiperOption: {
        slidesPerView: 1,
        speed: 600,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        on: {
          slideChange () {
            // $self.$refs['videoa'].player.pauseVideo();
          }
        },
      },
      showLightBox: false,
      loadingLightBox: false,
      swiperExtrafieldsAlias: [
        {
          media: 'media_link1',
          thumb: 'media_link1_thumb'
        },
        {
          media: 'media_link2',
          thumb: 'media_link2_thumb'
        },
        {
          media: 'media_link3',
          thumb: 'media_link3_thumb'
        },
      ]
    }
  },
  computed: {
    hasSwiper() {
      return this.swiperExtrafieldsAlias.some(item => {
        return this.getExtrafields(this.item, item.media)
      })
    },
    menuList() {
      return this.category.map(item=> {
        return {
          title: item.title,
          alias: item.alias,
        }
      })
    },
    ...setComputed,
  },
  methods: {
    getTag(item) {
      return item.tags.length > 0 ? item.tags[0].title : '橘色新訊';
    },
    onLightBoxClose() {
      this.showLightBox = false;
      this.$refs.videoPlayer.player.pause();
    },
    onLightBoxOpen(videoUrl) {
      this.playerOptions.sources[0].src = videoUrl;

      this.showLightBox = true;

      setTimeout(()=> {
        this.$refs.videoPlayer.player.play();
      }, 1000)
    },
    getFileType(fileName) {
      const extIndex = fileName.lastIndexOf('.');
      if (extIndex !== -1) {
        const type = fileName.substr(extIndex + 1, fileName.length);

        if(type === 'jpg' || type === 'jpeg' || type === 'png' || type === 'gif' || type === 'JPG'){
          return 'image'
        }else if(type === 'mp4'){
          return 'video'
        }else {
          return false
        }
      }else{
        return false
      }
    },
    getExtrafields(item, field) {
      if(field in item.extrafields){
        return item.extrafields[field].value;
      }else{
        return false
      }
    },
    formatNewItemDate
  },
}
</script>
<template>
  <Layout>
    <section :class="$style.sectionNewsItem">
      <div :class="$style.container">
        <div class="row">
          <div :class="$style.menuCol"></div>
          <div :class="$style.contentCol">
            <div :class="$style.breadcrumbBox" class="d-none d-lg-flex justify-content-between">
              <BaseBreadcrumb :list="breadcrumbs"/>
              <div></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div :class="$style.menuCol">
            <NewsSideMenu :list="menuList" />
          </div>
          <div :class="$style.contentCol">
            <div class="pb-4 pt-2 d-block d-lg-none">
              <BaseBreadcrumb :list="breadcrumbs"></BaseBreadcrumb>
            </div>
            <div :class="$style.articleBox">
              <h2 :class="$style.title">{{ item.title }}</h2>
              <hr>
              <div class="d-flex justify-content-between align-items-center" :class="$style.meta">
                <div class="d-flex" :class="$style.metaLeft">
                  <div :class="$style.date">
                    {{ formatNewItemDate(new Date(item.publish_up.replace(/-/g, "/"))) }}</div>
                  <router-link :to="{ name: 'news tag', params: { tagTitle: getTag(item) } }" :class="$style.tag">{{ getTag(item) }}</router-link>
                </div>
                <div :class="$style.metaRight">
                  <a :class="[$style.socialBtn, $style.facebook]"
                     target="_blank"
                     :href="`https://www.facebook.com/sharer/sharer.php?u=${locationHref}`"
                      class="facebook social-icon">
                  </a>
                </div>
              </div>
              <hr>
              <div :class="$style.contentBox">
                <div class="row flex-column flex-lg-row-reverse ">

                  <div v-if="hasSwiper" :class="$style.colImage" class="pb-4 pb-lg-0">
                    <swiper :class="$style.carousel" :options="swiperOption">
                      <template v-for="(alias, index) in swiperExtrafieldsAlias">
                        <template v-if="getExtrafields(item, alias.media)">
                          <swiper-slide
                            v-if="getFileType(getExtrafields(item, alias.media)) === 'image'"
                            :key="index"
                            :class="$style.carouselslide"
                            :style="{ backgroundImage:
                            `url(${currentWebsite.main}${getExtrafields(item, alias.media)})` }"
                          >
                          </swiper-slide>
                          <swiper-slide
                            v-if="getFileType(getExtrafields(item, alias.media)) === 'video'"
                            :key="index"
                            :class="[$style.carouselslide, $style.carouselVideo]"
                            :style="{ backgroundImage: `url(${currentWebsite.main}${getExtrafields(item, alias.thumb)})` }"
                          >
                            <transition name="zoom">
                              <button
                                v-if="!loadingLightBox"
                                :class="$style.play"
                                @click="onLightBoxOpen(`${currentWebsite.main}${getExtrafields(item, alias.media)}`)"
                              ></button>
                              <font-awesome-icon
                                v-else
                                :icon="['fal', 'circle-notch']"
                                :class="$style.loading"
                                class="fa-4x fa-spin"></font-awesome-icon>
                            </transition>
                          </swiper-slide>
                        </template>
                      </template>
                      <div slot="pagination" class="swiper-pagination carouselPagination"></div>
                    </swiper>
                  </div>
                  <div :class="[$style.description, hasSwiper ? $style.colContent : $style.colContentFull]"
                       v-html="item.description">
                  </div>
                </div>
              </div>
              <div :class="$style.navigationBox" class="d-flex justify-content-between">
                <div>
                  <router-link
                    v-if="item.previous"
                    :class="$style.navigationBtn"
                    :to="{ name: 'news item', params: { itemAlias: item.previous.alias } }">
                    <img
                      alt="上一篇"
                      src="@assets/images/icon/arrow_left.svg"
                      :class="$style.icon"
                      class="mr-3"
                    >
                    上一篇
                  </router-link>
                </div>
                <div>
                  <router-link
                    v-if="item.next"
                    :class="$style.navigationBtn"
                    :to="{ name: 'news item', params: { itemAlias: item.next.alias } }"
                  >
                    下一篇
                    <img
                      alt="下一篇"
                      src="@assets/images/icon/arrow_right.svg"
                      :class="$style.icon"
                      class="ml-3"
                    >
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <transition name="fade">
      <div v-if="showLightBox" :class="[$style.lightBox]">
        <videoPlayer
          ref="videoPlayer"
          :options="playerOptions"
          :class="$style.videoPlayer"
        />
        <button :class="$style.lightBoxClose" @click="onLightBoxClose">
          <font-awesome-icon :icon="['fal', 'times']"></font-awesome-icon>
        </button>
      </div>
    </transition>
  </Layout>
</template>
<style lang="scss" module>
  .sectionNewsItem {
    background-image: linear-gradient(to bottom, #f5f1ed, #dbd4cd);
    padding: 125px 0 110px;
    @include touch {
      padding: 2rem 0 4rem;
    }
    .container {
      @include container(1440px);
    }
    .menuCol {
      @include col(154px);
    }
    .contentCol {
      @include col(calc(1480px - 204px));
      flex: none;
      @media (max-width: 1480px) {
        @include col(calc(100vw - 274px));
      }
    }
    .breadcrumbBox {
      margin-bottom: 31px;
    }
    .articleBox {
      background: #fff;
      padding: 48px 50px;
      font-family: 'Noto Serif TC', serif;
      @include touch {
        padding: 30px 20px;
      }
      .title {
        @include font(26px, 38px, 3.96px, 600);
        margin-bottom: 2rem;
        @include touch {
          @include font(20px, 1.9, 2.2px, null);
          margin-bottom: 20px;
        }
      }
      .meta {
        .metaLeft {
          @include font(16px, 1rem, null, 400);
          font-family: 'Palatino', serif;
          @include touch {
            @include font(14px, null, null, 400);
          }
          & > div:not(:last-child) {
            position: relative;
            &:before {
              content: '';
              height: 18px;
              width: 1px;
              background: #000;
              position: absolute;
              right: 0;
              top: 1px;
            }
          }
        }
        .date {
          @include font(16px, 23px, 2.4px, 400);
          font-family: 'Palatino', serif;
          padding: 0 12px;
          @include touch {
            @include font(12px, 22px, 1.8px, null);
          }
        }
        .tag {
          @include font(16px, 23px, 1.92px, 500);
          padding: 0 16px;
          color: #000;
          @include touch {
            @include font(12px, 22px, 1.8px, null);
          }
          &:hover {
            color: #000;
            text-decoration: none;
          }
        }
      }
      .socialBtn {
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        text-align: center;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        &:hover {
          color: #ad9d8b;
          border-color: #ad9d8b;
        }
        &.facebook {
          background-image: url(@assets/images/icon/facebook_black.png);
          width: 20px;
          height: 20px;
          background-size: cover;
          display: block;
          background-position: center;
          background-repeat: no-repeat;
          &:hover {
            background-image: url(@assets/images/icon/facebook_brown.png);
          }
        }
        &.twitter {
          padding-left: 1px;
        }
      }
      hr {
        margin: 0.75rem 0;
        border-color: #000;
      }
      .contentBox {
        padding: 35px 0;
        @include touch {
          padding: 15px 0;
        }
        .description {
          font-weight: 500;
          letter-spacing: 0.96px;
        }
        .colContent {
          @include col(430px);
        }
        .colContentFull {
          @include col(100%);
        }
        .colImage {
          @include col(716px);
          flex: none;
          @media (max-width: 1480px) {
            @include col(calc((100vw - 400px) - 430px));
          }
        }
        a {
          color: #ad9d8b;
        }
        .carouselslide {
          padding-top: 93.295%;
          background-position: center;
          background-size: cover;
        }
        .carouselYoutube {
          iframe {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            height: 100%;
          }
        }
        .carouselVideo {
          position: relative;
          z-index: 0;
          &:before {
            @include bg-mask(rgba(#bea689, 0.1));
          }
          .play {
            appearance: none;
            border: 1px solid #fff;
            background: transparent;
            color: #fff;
            border-radius: 100%;
            height: 70px;
            width: 70px;
            font-size: 30px;
            padding-left: 13px;
            text-align: center;
            padding-right: 4px;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -35px;
            margin-left: -35px;
            &:before {
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 13px 0 13px 23px;
              border-color: transparent transparent transparent #ffffff;
              content: "";
              position: absolute;
              top: 50%;
              left: 50%;
              margin-left: -8px;
              margin-top: -12.5px;
            }
            &:hover {
              color: #ad9d8b;
              border-color: #ad9d8b;
              &:before {
                border-color: transparent transparent transparent #ad9d8b;
              }
            }
            &:active {
              font-size: 28px;
            }
            &:focus {
              outline: none;
            }
          }
          .loading {
            position: absolute;
            top: 50%;
            left: 50%;
            color: #fff;
            margin-left: -32px;
            margin-top: -32px;
            opacity: 0.5;
          }
        }
      }
      .navigationBox {
        margin-top: 25px;
        .navigationBtn {
          @include font(14px, 1.43, 1.12px, 300);
          color: #000;
          font-family: PingFangTC-Light, Noto Sans TC, sans-serif;
          &:hover {
            // color: #ad9d8b;
            text-decoration: none;
          }
          .icon {
            position: relative;
            top: -2px;
          }
        }
      }
    }

  }

  .lightBox {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 30;
    background: rgba(0, 0, 0, 0.96);
    transform: scale(1);
    .lightBoxClose {
      appearance: none;
      background: transparent;
      border: 0 solid transparent;
      position: absolute;
      top: 1rem;
      right: 1rem;
      color: #fff;
      font-size: 30px;
      opacity: 0.5;
      z-index: 1;
      &:hover {
        opacity: 1;
      }
      &:focus {
        outline: none;
      }
    }
  }
  .videoPlayer {
    & > div {
      height: 100vh;
      width: 100vw;
    }
  }
</style>
<style lang="scss">
  .carouselPagination {
    .swiper-pagination-bullet {
      opacity: 1;
      border: 1px solid #fff;
      background: transparent;
      &-active {
        background: #fff;
      }
    }
  }
  .swiper-container {
    z-index: 0;
  }
</style>
<style>
  .video-js .vjs-big-play-button {
    top: 50%;
    left: 50%;
    display: none;
  }
</style>
