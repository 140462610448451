<script>
  export default {
    props: {
      list: {
        type: Array,
        required: true
      }
    },
    methods: {
      checkSideMenuActive(index, alias) {
        return this.$route.params.categoryAlias === alias
      },
    }
  };
</script>

<template>
  <div :class="$style.sideMenu">
    <div class="row" :class="$style.sideMenuList">
      <div class="col-4 col-lg-12" :class="$style.sideMenuItem">
        <router-link
          :to="{ name: 'news' }"
          :class="[$style.sideMenuLink,
          { [$style.hasActive]: !this.$route.params.categoryAlias }]"
        >
          {{ $t("global.NEWS_GENERAL_NEWS") }}
        </router-link>
      </div>
      <div v-for="(categoryItem, index) in list" :key="index" class="col-4 col-lg-12" :class="$style.sideMenuItem">
        <router-link
          :to="{ name: 'news category', params: { categoryAlias: categoryItem.alias } }"
          :key="`${index}category`"
          :class="[$style.sideMenuLink,
          { [$style.hasActive]: checkSideMenuActive(index, categoryItem.alias) }]"
        >
          {{categoryItem.title}}
        </router-link>
      </div>
    </div>
  </div>
</template>
<style lang="scss" module>
  .sideMenu {
    margin-bottom: 1.5rem;
    @include touch {
      margin-left: -8px;
      margin-right: -8px;
    }
    .sideMenuList {
      margin-left: -5px;
      margin-right: -5px;
      .sideMenuItem {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
    .sideMenuLink {
      @include font(17px, null, 3px, 600);
      font-family: 'Noto Serif TC', serif;
      display: block;
      color: #000;
      border: 1px solid #000;
      width: fit-content;
      padding: 0.1rem 9px;
      margin: 0.6rem 0;
      transition: margin 0.3s;
      white-space: nowrap;
      @include touch {
        @include font(16px, 19px, 2.34px, null);
        margin: 0 auto;
        padding: 4px 9.5px;
      }
      &:hover {
        text-decoration: none;
      }
      /* &:hover:not(.hasActive) {
        margin-left: 1rem;
        position: relative;
        @include touch {
          margin-left: auto;
        }
        &:before {
          content: "";
          position: absolute;
          left: -1rem;
          top: 50%;
          width: 0.5rem;
          height: 1px;
          background: #000;
          @include touch {
            display: none;
          }
        }
      } */
      &.hasActive {
        margin-left: 1.5rem;
        position: relative;
        @include touch {
          background: #000;
          color: #fff;
          margin-left: auto;
        }
        &:before {
          content: "";
          position: absolute;
          left: -1.5rem;
          top: 50%;
          width: 1rem;
          height: 1px;
          background: #000;
          @include touch {
            display: none;
          }
        }
      }
    }
  }
</style>
